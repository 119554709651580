<template>
  <div class="oa-approval-user">
    <!-- 协作人列表 -->
    <van-popup v-model="coopsVisible" position="bottom" get-container="body">
      <coops :coopId="coopId" @select="coopSelect" />
    </van-popup>
    <!-- 审批和抄送人 -->
    <van-popup v-model="approvalVisible" position="bottom" get-container="body">
      <div class="approval-title">
        {{ userTitle }}
      </div>
      <van-radio-group v-model="userid">
        <van-cell-group>
          <van-cell
            v-for="(option, i) in userList"
            :key="i"
            :title="option.label"
            clickable
            @click="userSelect(option)"
          >
            <van-radio slot="right-icon" :name="option.userid" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-popup>
    <div class="oa-approval">
      <div class="oa-approval-title">
        <span>{{ caseMode === 0 ? '审批模式' : '指派模式' }}</span>
        <span v-if="isShowCaseMode" @click="changeCaseMode">{{
          caseMode === 0 ? '切换指派模式' : '切换到审批模式'
        }}</span>
      </div>
      <div
        class="oa-approval-step"
        @click="switchUser(0)"
        v-if="this.Approval.length > 0 && this.CC.length > 0"
      >
        <div class="oa-approval-step-div">
          <div class="oa-approval-step-dot"></div>
          <div class="oa-approval-step-Approver">
            {{ caseMode === 0 ? '审批人' : '接收人' }}
          </div>
        </div>
        <div class="oa-approval-userInfo">
          <div class="oa-approval-userInfo-avatar">
            {{ sliceName(approvalUser.avatarUrl) }}
          </div>
          <div>{{ approvalUser.label }}</div>
        </div>
      </div>
      <div
        class="oa-approval-step"
        @click="switchUser(1)"
        v-if="this.Approval.length > 0 && this.CC.length > 0"
      >
        <div class="oa-approval-step-div">
          <div class="oa-approval-step-dot"></div>
          <div class="oa-approval-step-Approver">抄送人</div>
        </div>
        <div class="oa-approval-userInfo">
          <div class="oa-approval-userInfo-avatar">
            {{ sliceName(ccUser.avatarUrl) }}
          </div>
          <div>{{ ccUser.label }}</div>
        </div>
      </div>
      <div
        class="oa-approval-step-line"
        v-if="this.Approval.length > 0 && this.CC.length > 0"
      ></div>
      <div v-if="this.Approval.length <= 0 || this.CC.length <= 0">
        未设置本流程审批人或抄送人，请联系本所管理员进行设置。
      </div>
    </div>
  </div>
</template>

<script>
import coops from '../../components/coops'

export default {
  name: 'approval-user',
  components: {
    coops
  },
  props: {
    isShowCaseMode: {
      type: Boolean,
      default: () => false
    },
    caseMode: {
      type: Number,
      default: () => 0
    },
    isAdd: Number,
    CC: {
      type: Array,
      default: () => []
    },
    Approval: {
      type: Array,
      default: () => []
    },
    ccUserSource: {
      type: Object,
      default: () => {}
    },
    approvalUserSource: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      approvalVisible: false,
      userTitle: '',
      userList: [],
      userid: 0,
      userType: 0,
      ccUser: {},
      approvalUser: {},
      coopUser: null, // 协作人
      coopId: 0, // 协作人id
      coopsVisible: false // 协作人弹框
    }
  },
  computed: {},
  methods: {
    changeCaseMode() {
      let caseMode
      if (this.caseMode === 0) {
        caseMode = 1
      } else {
        caseMode = 0
      }
      this.$emit('changeCaseMode', caseMode)
    },
    // 协作人点击事件
    switchCooper() {
      this.coopsVisible = !this.coopsVisible
    },
    coopSelect(options) {
      this.coopUser = options
      this.coopId = options.id
      this.$emit('coopSelect', options)
      this.switchCooper()
    },
    // 截取最后两个字
    sliceName(userName) {
      return userName.slice(userName.length - 2)
    },
    // 审批抄送人点击事件 0审批  1抄送
    switchUser(type) {
      this.userType = type
      if (type === 0) {
        this.userList = this.Approval
        this.userid = this.approvalUser.userid
        this.userTitle = '请选择审批人'
      } else {
        this.userList = this.CC
        this.userid = this.ccUser.userid
        this.userTitle = '请选择抄送人'
      }
      this.approvalVisible = !this.approvalVisible
    },
    userSelect(user) {
      this.switchUser(this.userType)
      if (this.userType === 0) {
        this.approvalUser = user
      } else {
        this.ccUser = user
      }
      const userList = [this.approvalUser, this.ccUser]
      this.$emit('userSelect', userList)
    }
  },
  mounted() {},
  created() {
    this.ccUser = this.ccUserSource
    this.approvalUser = this.approvalUserSource
    console.log(this.approvalUser)
  }
}
</script>

<style lang="stylus" scoped>
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.oa-approval-user
  position relative
  background #FFF
  & .oa-approval
    border-top 10px solid rgba(242, 242, 242, 1)
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
      display flex
      justify-content space-between
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        & .oa-approval-userInfo-avatar
          width 35px
          height 35px
          border-radius 20px
          background #008AFF
          text-align center
          line-height 35px
          color #FFF
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
</style>
