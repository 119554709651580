var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "case-list-comp" },
    [
      _c(
        "div",
        [
          _c("van-search", {
            attrs: { placeholder: "请输入搜索关键词" },
            on: { input: _vm.searchInput },
            model: {
              value: _vm.key,
              callback: function ($$v) {
                _vm.key = $$v
              },
              expression: "key",
            },
          }),
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: !_vm.isRequest,
                "finished-text": "没有更多了",
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "van-radio-group",
                    {
                      model: {
                        value: _vm.coopIdCopy,
                        callback: function ($$v) {
                          _vm.coopIdCopy = $$v
                        },
                        expression: "coopIdCopy",
                      },
                    },
                    [
                      _c(
                        "van-cell-group",
                        _vm._l(_vm.coopList, function (option, i) {
                          return _c(
                            "van-cell",
                            {
                              key: i,
                              attrs: { title: option.nickName, clickable: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.select(option)
                                },
                              },
                            },
                            [
                              _c("van-radio", {
                                attrs: { slot: "right-icon", name: option.id },
                                slot: "right-icon",
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }