<template>
  <div class="case-list-comp">
    <div>
      <van-search
        v-model="key"
        placeholder="请输入搜索关键词"
        @input="searchInput"
      />
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="!isRequest"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div>
          <van-radio-group v-model="coopIdCopy">
            <van-cell-group>
              <van-cell
                v-for="(option, i) in coopList"
                :key="i"
                :title="option.nickName"
                clickable
                @click="select(option)"
              >
                <van-radio slot="right-icon" :name="option.id" />
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'coops-comp',
  props: {
    title: {
      type: String,
      default: () => '协作人列表'
    },
    coopId: Number
  },
  data() {
    return {
      isRequest: true, // 是否可以继续请求
      loading: true, // 是否处于加载状态
      refreshing: false, // 是否处于下拉刷新状态
      coopIdCopy: 0, // 案件ID
      key: '', // 案件名称
      coopList: [], // 协作人列表
      page: 0,
      size: 10
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    select(options) {
      this.coopIdCopy = options.id
      this.$emit('select', options)
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
    },

    // 获取协作人列表
    getItems() {
      this.loading = true
      this.$axios
        .get(
          `${this.$base}/management/organization/${
            this.organizationId
          }/users?page=${this.page}&size=${this.size}&key=${this.key}`
        )
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.coopList = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                this.coopList = this.coopList.concat(res.data.data.content)
              }
              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          }
          this.loading = false
          this.refreshing = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 搜索事件
    searchInput() {
      this.coopList = []
      this.page = 0
      this.isRequest = true
      this.getItems()
    },

    // 上拉加载事件
    onLoad() {
      this.page++
      this.loading = true
      this.getItems()
    },

    // 下拉刷新
    onRefresh() {
      this.coopList = []
      this.page = 0
      this.isRequest = true
      this.getItems()
    }
  },
  mounted() {},
  async created() {
    await this.getId()
    this.coopIdCopy = this.coopId
    this.getItems()
  }
}
</script>

<style lang="stylus">
.case-list-comp
  position relative
  padding-bottom 10px
  box-sizing border-box
  max-height 400px
  overflow scroll
  & .title, .popup-item__option
    text-align center
    height 40px
    line-height 40px
    font-size 16px
    &.active
      color #1989fa
  & .title
    color rgba(69, 90, 100, 0.6)
</style>
