var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "oa-approval-user" },
    [
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "get-container": "body" },
          model: {
            value: _vm.coopsVisible,
            callback: function ($$v) {
              _vm.coopsVisible = $$v
            },
            expression: "coopsVisible",
          },
        },
        [
          _c("coops", {
            attrs: { coopId: _vm.coopId },
            on: { select: _vm.coopSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "get-container": "body" },
          model: {
            value: _vm.approvalVisible,
            callback: function ($$v) {
              _vm.approvalVisible = $$v
            },
            expression: "approvalVisible",
          },
        },
        [
          _c("div", { staticClass: "approval-title" }, [
            _vm._v(" " + _vm._s(_vm.userTitle) + " "),
          ]),
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.userid,
                callback: function ($$v) {
                  _vm.userid = $$v
                },
                expression: "userid",
              },
            },
            [
              _c(
                "van-cell-group",
                _vm._l(_vm.userList, function (option, i) {
                  return _c(
                    "van-cell",
                    {
                      key: i,
                      attrs: { title: option.label, clickable: "" },
                      on: {
                        click: function ($event) {
                          return _vm.userSelect(option)
                        },
                      },
                    },
                    [
                      _c("van-radio", {
                        attrs: { slot: "right-icon", name: option.userid },
                        slot: "right-icon",
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "oa-approval" }, [
        _c("div", { staticClass: "oa-approval-title" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.caseMode === 0 ? "审批模式" : "指派模式")),
          ]),
          _vm.isShowCaseMode
            ? _c("span", { on: { click: _vm.changeCaseMode } }, [
                _vm._v(
                  _vm._s(_vm.caseMode === 0 ? "切换指派模式" : "切换到审批模式")
                ),
              ])
            : _vm._e(),
        ]),
        this.Approval.length > 0 && this.CC.length > 0
          ? _c(
              "div",
              {
                staticClass: "oa-approval-step",
                on: {
                  click: function ($event) {
                    return _vm.switchUser(0)
                  },
                },
              },
              [
                _c("div", { staticClass: "oa-approval-step-div" }, [
                  _c("div", { staticClass: "oa-approval-step-dot" }),
                  _c("div", { staticClass: "oa-approval-step-Approver" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.caseMode === 0 ? "审批人" : "接收人") +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "oa-approval-userInfo" }, [
                  _c("div", { staticClass: "oa-approval-userInfo-avatar" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.sliceName(_vm.approvalUser.avatarUrl)) +
                        " "
                    ),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.approvalUser.label))]),
                ]),
              ]
            )
          : _vm._e(),
        this.Approval.length > 0 && this.CC.length > 0
          ? _c(
              "div",
              {
                staticClass: "oa-approval-step",
                on: {
                  click: function ($event) {
                    return _vm.switchUser(1)
                  },
                },
              },
              [
                _vm._m(0),
                _c("div", { staticClass: "oa-approval-userInfo" }, [
                  _c("div", { staticClass: "oa-approval-userInfo-avatar" }, [
                    _vm._v(
                      " " + _vm._s(_vm.sliceName(_vm.ccUser.avatarUrl)) + " "
                    ),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.ccUser.label))]),
                ]),
              ]
            )
          : _vm._e(),
        this.Approval.length > 0 && this.CC.length > 0
          ? _c("div", { staticClass: "oa-approval-step-line" })
          : _vm._e(),
        this.Approval.length <= 0 || this.CC.length <= 0
          ? _c("div", [
              _vm._v(
                " 未设置本流程审批人或抄送人，请联系本所管理员进行设置。 "
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "oa-approval-step-div" }, [
      _c("div", { staticClass: "oa-approval-step-dot" }),
      _c("div", { staticClass: "oa-approval-step-Approver" }, [
        _vm._v("抄送人"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }